import axios from "axios";
import React, {  useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import MenuDesplegableCategoria from "../../components/Menu_Desplegable_Categoria";
import DetallesProductos from "../../Products/Detalles_Producto";
import AllProductsSearch from "../../Products/AllProductsSearch";
const ProductDetails = () => {
  const params = useParams();
  const [product, setProduct] = useState([]);
  const [relatesProducts, setRelatesProducts] = useState([]);

  //get product
  const getProduct = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/productos/${params?.id}`
      );
      setProduct(data.body);
      getSimilarProducts(data.body[0].categoria);
    } catch (error) {
      getSimilarProducts([])
    }
  };

  // get similiar products
  const getSimilarProducts = async (pid) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/productos/producto-categoria/${pid}`
      );
      setRelatesProducts(data?.body);
    } catch (error) {
      setRelatesProducts([])
    }
  };
  getProduct();

  return (
    <Layout>
      <div
        className="container"
        style={{ alignItems: "center !important", marginTop: "15px" }}
      >
        <MenuDesplegableCategoria />

        <DetallesProductos producto={product} />
      </div>

      <hr />
      <div className="row ">
        <h1>Productos Similares</h1>
        {relatesProducts.length === 0 ? (
          <p className="text-center">No Similar Products found</p>
        ) : (
          <AllProductsSearch search={relatesProducts} />
        )}
      </div>
    </Layout>
  );
};

export default ProductDetails;
