import React from "react";
import { useAuth } from "../context/auth.js";
import Layout from "../Layout/Layout.js";
import { useCart } from "../Carrito/use_Cart_Hooks.js";
import AllProductsCarrito from "../Carrito/AllProducts_Cart.js";
import DatosAdicionales from "../Carrito/DatosAdicionales.js";
const CartPage = () => {
  const { cart } = useCart();
  const [auth] = useAuth();
  return (
    <Layout title={"SendCuba - Compras"}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center  p-2 mb-1">
              {auth
                ? `Buenas, ${auth?.token && auth?.user}.`
                : "Por favor, antes de continuar, debe logearse"}
            </h1>
            <h4 className="text-center">
              {cart !== null && cart.length > 0
                ? `Pedido a la provincia de ${cart}`
                : null}
            </h4>
            <h4 className="text-center">
              {cart?.length ? null : "No posee ningun producto para comprar"}
            </h4>
          </div>
        </div>
        <div className="row">
          <AllProductsCarrito />
          <DatosAdicionales />
        </div>
      </div>
    </Layout>
  );
};

export default CartPage;
