import React from "react";
import Layout from "../Layout/Layout";
import AllProducts from "../Products/AllProducts";
import MenuDesplegableCategoria from "../components/Menu_Desplegable_Categoria";
const HomePage = () => {
  return (
    <Layout title={"All Products - SendCuba"}>
      <MenuDesplegableCategoria />
      <AllProducts />
    </Layout>
  );
};

export default HomePage;
