import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../context/auth";
import { BASE_URL } from "../Routes/global-env";
import toast from "react-hot-toast";
import axios from "axios";

const DetallesPedido = () => {
  const [pedidos, setPedidos] = useState([]);
  const params = useParams();

  const [auth] = useAuth();

  const navigate = useNavigate();

  //get all pedidos
  const getAllPedidos = async () => {
    try {
      if (auth.email) {
        const { data } = await axios.get(
          `${BASE_URL}/api/pedidos/pedido/${params.id}`
        );
        if (data.body) {
          setPedidos(data.body);
        }
      }
    } catch (error) {
      toast.error("Something went wrong in getting category");
    }
  };
  const handleDelete = async (id) => {
    await axios.post(`${BASE_URL}/api/pedidos/mostrar/${id}`);
    setTimeout(() => {
      toast.success("Pedido Eliminado");
    }, 500);
    getAllPedidos();

    navigate("/pedidos");
  };

  useEffect(() => {
    getAllPedidos();
  }, [auth]);

  function formatDate(date) {
    const formattedDate = new Date(date);

    const day = formattedDate.getDate();
    const month = formattedDate.getMonth() + 1;
    const year = formattedDate.getFullYear();

    return `${day}/${month}/${year}`;
  }
  return (
    <div className="row gx-4 gx-lg-5 row-cols-1 row-cols-md-2 row-cols-xl-2 justify-content-center">
      {pedidos?.map((pedido) => (
        <div className="card" style={{ padding: "10px" }}>
          <div className="card-header">
            <div className="container">
              <div className="row row-cols-2">
                <div className="col">Numero del Pedido: {pedido.id}</div>
                <div className="col">Estado: {pedido.estado}</div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <h5 className="card-title">
              <div className="container">
                <div className="row row-cols-2">
                  <div className="col" style={{ padding: "0px" }}>
                    <h5>Detalles del Receptor</h5>
                  </div>
                  <div className="col">
                    <h6 style={{ color: "gray" }}>Fecha:</h6>
                    {formatDate(pedido.fecha)}
                  </div>
                </div>
              </div>
            </h5>
            <h6 style={{ color: "gray" }}>Nombre:</h6>
            <span>{pedido.nombre_recibe}</span>
            <hr />
            <h6 style={{ color: "gray" }}>Direccion:</h6>
            <span>{pedido.direccion_recibe}</span> <hr />
            <h6 style={{ color: "gray" }}>Telefono:</h6>
            <span>{pedido.telefono_recibe}</span>
            <hr />
            <p className="card-text">
              {pedido.descripcion.split(",").map((desc) => (
                <p>
                  {desc.replace(/[`!"#$%&'*+,\-./;<=>?@[\\\]^_`{|}~]/g, "")}
                </p>
              ))}
            </p>
            <hr />
            <h6> Aclaracion:</h6>
            <p>{pedido.aclaracion}</p>
            <hr />
            <div className="row row-cols-2">
              <div className="col">
                {" "}
                <h6 style={{ color: "black" }}>Precio:</h6>{" "}
                {pedido.total_precio}
              </div>
              <div className="col">
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(pedido.id)}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DetallesPedido;
