import React from "react";
import { useAuth } from "../../context/auth";
import { Link, NavLink } from "react-router-dom";
import toast from "react-hot-toast";

const Dropdwon = () => {
  const [auth, setAuth] = useAuth();

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      admin: "",
      token: "",
    });
    localStorage.removeItem("user");
    setTimeout(() => {
      toast.success("Logout Success");
    }, 500);
  };
  return (
    <div className="col-4">
      <li className="nav-item dropdown" style={{ listStyleType: "none" }}>
        <Link
          // href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className="nav-link dropdown-toggle"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={25}
            fill="currentColor"
            className="bi bi-person"
            viewBox="0 0 16 16"
          >
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
          </svg>
        </Link>

        <ul className="dropdown-menu">
          <li style={{ marginLeft: "10px", marginRight: "10px" }}>
            <h6>{auth?.user}</h6>
          </li>
          {auth?.admin === "SI" ? (
            <>
              <li style={{ marginLeft: "10px", marginRight: "10px" }}>
                <NavLink
                  to={`/dashboard/${auth?.admin === "SI" ? "admin" : "user "}`}
                  className="dropdown-item"
                  style={{ borderRadius: "20px" }}
                >
                  Gestión
                </NavLink>
              </li>
            </>
          ) : null}
          <li style={{ marginLeft: "10px", marginRight: "10px" }}>
            <NavLink
              to={"/pedidos"}
              className="dropdown-item"
              style={{ borderRadius: "20px" }}
            >
              Pedidos
            </NavLink>
          </li>
          <li style={{ marginLeft: "10px", marginRight: "10px" }}>
            <NavLink
              to="/login"
              className="dropdown-item"
              onClick={handleLogout}
              style={{ borderRadius: "20px" }}
            >
              Logout
            </NavLink>
          </li>
        </ul>
      </li>{" "}
    </div>
  );
};

export default Dropdwon;
