import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { BASE_URL } from "../Routes/global-env";
import { useCart } from "../Carrito/use_Cart_Hooks";
import ModalSelectProvincia from "./ModalCambioProvincia";
import Modal_Select_Provincia from "./ModalSelectProvincia";

const AllProducts = () => {
  const [products, setProducts] = useState([]);
  const [cantidad, setCantidad] = useState(1);
  const getProducts = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/productos`);
      setProducts(data?.body);
    } catch (error) {
      setProducts([]);
      toast.error("No se pudo cargar los Productos");
    }
  };
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  const [provinciaError, setProvinciaError] = useState(false);

  const [producto, setProducto] = useState();
  const { addToCart } = useCart();

  useEffect(() => {
    getProducts();
    setProvinciaSeleccionada(localStorage.getItem("provincia"));
  }, [provinciaSeleccionada]);
  return (
    <>
      <section style={{ margin: "25px" }}>
        <div
          className="container px-lg-5 "
          style={{ paddingRight: "0px", paddingLeft: "0px" }}
        >
          <div className="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
            {products?.map((p, index) => (
              <div
                key={index}
                className="col mb-5"
                style={{ paddingRight: "3px", paddingLeft: "0px" }}
              >
                <div
                  className="card h-100"
                  key={index}
                  style={{
                    boxShadow: " 0px 10px 20px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <>
                    <Link style={{ color: "black" }} to={`/product/${p.id}`}>
                      <img
                        className="imagenes imagenesHome"
                        src={`${BASE_URL}/api/productos/imagen/${p.id}`}
                        alt={p.nombre}
                      />
                      {/* Product details*/}
                      <div className="card-body p-1">
                        <div className="text-center">
                          {/* Product name*/}
                          <h5 className="fw-bolder">{p.nombre}</h5>
                          {/* Product price*/}$ {p.precio}
                          <hr style={{ margin: "0px" }} />
                          <hr style={{ margin: "0px" }} />
                          <h6>Provincia: {p.provincia}</h6>
                        </div>
                      </div>
                    </Link>
                  </>

                  <div
                    className="row align-items-end justify-content-between"
                    style={{ marginBottom: "5%" }}
                  >
                    <div
                      className="col-8"
                      style={{
                        width: "57.666667%",
                        paddingLeft: "12px",
                        paddingRight: "0px",
                      }}
                    >
                      {" "}
                      <div className="input-group">
                        <button
                          style={{ paddingLeft: "7px" }}
                          className="btn btn-default"
                          onClick={() => {
                            if (cantidad > 1) {
                              setCantidad((p.cantidad = p.cantidad - 1));
                            }
                          }}
                        >
                          -
                        </button>

                        <input
                          type="text"
                          className="form-control"
                          value={p.cantidad}
                          disabled
                          readOnly
                          onChange={() => setCantidad(p.cantidad)}
                        />

                        <button
                          style={{ paddingRight: "7px" }}
                          className="btn btn-default"
                          onClick={() =>
                            setCantidad((p.cantidad = p.cantidad + 1))
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <div
                      className="col "
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginRight: "5px",
                      }}
                    >
                      <button
                        className="btn btn-outline-success mt-auto justify-content-md-end"
                        onClick={() => {
                          if (provinciaSeleccionada === null) {
                            addToCart(p);
                            localStorage.setItem("provincia", p.provincia);
                          } else {
                            if (provinciaSeleccionada === p.provincia) {
                              addToCart(p);
                            } else {
                              setProvinciaError(true);
                              setProducto(p);
                              console.log("error");
                            }
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={21}
                          height={21}
                          fill="currentColor"
                          className="bi bi-cart-check-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <ModalSelectProvincia
        isOpen={provinciaError}
        producto={producto}
        url={true}
        onClose={() => setProvinciaError(false)}
      />
      <Modal_Select_Provincia
        isOpen={provinciaSeleccionada === null}
        onClose={() => setProvinciaError(false)}
      />
    </>
  );
};

export default AllProducts;
