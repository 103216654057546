import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from "../../Routes/global-env";
import AdminMenu from "../../components/AdminMenu";
import Layout from "../../Layout/Layout";
import Categoryform from "../../Form/Categoryform";
const CreateProvincia = () => {
  const [provinicias, setProvinicias] = useState([]);
  const [nombre, setnombre] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [editing, setEditing] = useState(null);

  //handle form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${BASE_URL}/api/provincias`, {
        nombre,
      });
      if (data?.body) {
        toast.success(`${nombre} creado`);
        setnombre("");
        getAllProvincias();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing went wrong in input form");
    }
  };

  //get all provinicias
  const getAllProvincias = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/provincias`);
      if (data.body) {
        setProvinicias(data.body);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting Provincia");
    }
  };

  useEffect(() => {
    getAllProvincias();
  }, []);

  //update Name Provincia
  const handleUpdate = async (id) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/api/provincias/${id}`, {
        nombre: updateName,
      });
      if (data?.body) {
        toast.success(`${updateName} actualizado`);
        setUpdateName("");
        getAllProvincias();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing went wrong ");
    }
  };

  //Delete Provincia
  const handleDelete = async (pid) => {
    try {
      const { data } = await axios.put(`${BASE_URL}/api/provincias/${pid}`);
      if (data?.body) {
        toast.success(`Provincia eliminado`);
        getAllProvincias();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing went wrong ");
    }
  };

  return (
    <Layout title={"Dashborad - Create Provincia"}>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1>Manage Provincia</h1>
            <div className="p-3 w-50">
              <Categoryform
                handleSubmit={handleSubmit}
                value={nombre}
                setValue={setnombre}
              />
            </div>
            <div className="w-75">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                {provinicias?.map((c) => (
                  <tbody key={c.id}>
                    <>
                      <tr>
                        <td>
                          {editing === c.id ? (
                            <input
                              value={updateName}
                              onChange={(e) => setUpdateName(e.target.value)}
                            />
                          ) : (
                            c.nombre
                          )}
                        </td>

                        <td>
                          {editing !== c.id && (
                            <>
                              <button
                                className="btn btn-warning ms-2"
                                onClick={() => {
                                  setUpdateName(c.nombre);
                                  setEditing(c.id);
                                }}
                              >
                                Editar
                              </button>
                              <button
                                className="btn btn-danger ms-2"
                                onClick={() => {
                                  handleDelete(c.id);
                                }}
                              >
                                Eliminar
                              </button>
                            </>
                          )}

                          {editing === c.id && (
                            <>
                              <button
                                className="btn btn-primary ms-2"
                                onClick={() => {
                                  handleUpdate(c.id);
                                  setEditing(null);
                                }}
                              >
                                Guardar
                              </button>
                              <button
                                className="btn btn-danger ms-2"
                                onClick={() => setEditing(null)}
                              >
                                Cancelar
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    </>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CreateProvincia;
