import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BASE_URL } from "../../Routes/global-env";
import toast from "react-hot-toast";

const Dropdown = () => {
  const [provinciaSelect, setprovinciaSelect] = useState([]);
  const getAllProvincia = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/provincias`);
      if (data.body) {
        setprovinciaSelect(data.body);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error al conectarse a la Base de Datos de Provincias");
    }
  };

  const [selectedProvincia, setSelectedProvincia] = useState("Provincias");
  const handleSelect = (provinciaSelect) => {
    setSelectedProvincia(provinciaSelect);
  };
  useEffect(() => {
    getAllProvincia();
  }, []);
  return (
    <li className="nav-item dropdown" style={{ listStyleType: "none" }}>
      <NavLink
        className="nav-link dropdown-toggle"
        to={"/categories"}
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="true"
        style={{ fontSize: "16px" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={16}
          height={16}
          fill="currentColor"
          className="bi bi-geo-alt-fill"
          viewBox="0 0 16 16"
        >
          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
        </svg>{" "}
        {selectedProvincia}
      </NavLink>

      <ul className="dropdown-menu">
        <li style={{ marginLeft: "10px", marginRight: "10px" }}>
          <NavLink
            className="dropdown-item"
            to={`/`}
            style={{ borderRadius: "20px" }}
            onClick={() => handleSelect("Todas")}
          >
            Todas
          </NavLink>
        </li>
        {provinciaSelect?.map((c, index) => (
          <li key={index} style={{ marginLeft: "10px", marginRight: "10px" }}>
            <NavLink
              className="dropdown-item"
              to={`/provincia/${c.nombre}`}
              style={{ borderRadius: "20px" }}
              onClick={() => handleSelect(c.nombre)}
            >
              {c.nombre}
            </NavLink>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default Dropdown;
