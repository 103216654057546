import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../context/auth";
import Spinner from "./Spinner";

export default function AdminRoute() {
  const [ok, setOk] = useState(false);
  const [auth, setAuth] = useAuth();
  console.log(auth);
  useEffect(() => {
    const authCheck = async () => {
      try {
        if (auth?.admin === "SI") {
          setOk(true);
        } else {
          setOk(false);
        }
      } catch (error) {
        console.log("Request failed with status code 401");
      }
    };
    if (auth) authCheck();
  }, [auth]);

  return ok ? <Outlet /> : <Spinner path="" />;
}
