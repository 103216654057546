import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import AdminMenu from "../../components/AdminMenu";
import axios from "axios";
import toast from "react-hot-toast";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
const { Option } = Select;

const UpdateProducts = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [categories, setCategories] = useState([]);

  const [provincia, setProvincia] = useState([]);
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [descripction, setDescripction] = useState("");
  const [costo, setCosto] = useState("");
  const [prov, setProv] = useState("");
  const [price, setPrice] = useState("");
  const [photo, setPhoto] = useState("");
  const [imagen, setImagen] = useState("");

  //get single product
  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/productos/${params.slug}`
      );
      setName(data.body[0].nombre);
      setDescripction(data.body[0].descripcion);
      setPrice(data.body[0].precio);
      setCosto(data.body[0].costo);
      setProv(data.body[0].provincia);
      setPhoto(data.body[0].imagen);
      setCategory(data.body[0].categoria);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSingleProduct();
    //eslint-disable-next-line
  }, []);
  //get all products
  const getAllCategories = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/categorias`);
      if (data.body) {
        setCategories(data.body);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting category");
    }
  };
  const getAllProvincias = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/provincias`);
      if (data.body) {
        setProvincia(data.body);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting category");
    }
  };

  useEffect(() => {
    getAllCategories();
    getAllProvincias();
  }, []);

  //create product function
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const productData = new FormData();
      productData.append("nombre", name);
      productData.append("descripcion", descripction);
      productData.append("precio", price);
      productData.append("costo", costo);
      productData.append("categoria", category);
      productData.append("provincia", prov);
      if (imagen === "") {
        productData.append("imagen", photo);
      } else {
        productData.append("imagen", imagen);
      }
      const { data } = await axios.post(
        `${BASE_URL}/api/productos/producto/${params.slug}`,
        productData
      );
      console.log(data);
      if (data?.error === false) {
        setTimeout(() => {
          toast.success("Producto Actualizado");
        }, 500);
        navigate("/dashboard/admin/products");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title={"Dashborad - Create Product"}>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1 className="text-center">Actualizar Producto</h1>
            {/* <form> */}
            <div className="mb-3">
              <input
                placeholder="Nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <textarea
                placeholder="Descripcion"
                value={descripction}
                onChange={(e) => setDescripction(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <input
                placeholder="Precio"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                type="number"
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <input
                placeholder="Costo"
                value={costo}
                onChange={(e) => setCosto(e.target.value)}
                type="number"
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <Select
                bordered={false}
                placeholder="Selecciona la Categoria"
                size="large"
                value={category}
                showSearch
                className="form-select mb-3"
                onChange={(value) => {
                  setCategory(value);
                }}
              >
                {categories?.map((c) => (
                  <Option key={c.id} value={c.nombre}>
                    {c.nombre}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="mb-3">
              <Select
                bordered={false}
                placeholder="Selecciona la Provincia"
                size="large"
                showSearch
                value={prov}
                className="form-select mb-3"
                onChange={(value) => {
                  setProv(value);
                }}
              >
                {provincia?.map((c) => (
                  <Option key={c.id} value={c.nombre}>
                    {c.nombre}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="mb-3 text-center">
              <label className="btn btn-outline-secondary col-md-12">
                {imagen ? imagen.name : photo}
                <input
                  type="file"
                  name="photo"
                  accept="image/*"
                  onChange={(e) => setImagen(e.target.files[0])}
                  hidden
                />
              </label>
            </div>
            <div className="mb-3 text-center">
              {imagen === "" ? (
                <div className="text-center">
                  <img
                    src={`${BASE_URL}/api/productos/imagen/${params.slug}`}
                    alt="product_photo"
                    height={"200px"}
                    className="img img-responsive"
                  />
                </div>
              ) : (
                <div className="text-center">
                  <img
                    src={URL.createObjectURL(imagen)}
                    alt="product_photo"
                    height={"200px"}
                    className="img img-responsive"
                  />
                </div>
              )}
            </div>

            <div className="mb-3 text-center">
              <button className="btn btn-primary" onClick={handleUpdate}>
                Actualizar Producto
              </button>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UpdateProducts;
