import React, { useEffect, useState } from "react";
import useCategory from "../hooks/useCategoys.js";
import { NavLink } from "react-router-dom";

const MenuDesplegableCategoria = () => {
  const [isMobile, setIsMobile] = useState(false);
  const categories = useCategory();
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addListener(handleResize);
    handleResize();
    return () => mediaQuery.removeListener(handleResize);
  }, []);
  return (
    <>
      {isMobile ? (
        <div className="filtersMobil">
          {categories?.map((c, index) => (
            <NavLink
              key={index}
              className="btn btn-light"
              to={`/category/${c.nombre}`}
              style={{
                borderRadius: "var(--bs-btn-border-radius)",
                display: "block",
                margin: "10px",
              }}
            >
              {c.nombre}
            </NavLink>
          ))}
        </div>
      ) : (
        <div className="filters">
          {categories?.map((c, index) => (
            <NavLink
              key={index}
              className="btn btn-light"
              to={`/category/${c.nombre}`}
              style={{
                borderRadius: "var(--bs-btn-border-radius)",
                display: "block",
                margin: "10px",
              }}
            >
              {c.nombre}
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
};

export default MenuDesplegableCategoria;
