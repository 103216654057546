import React, {  useState } from "react";
import SearchInput from "../Form/SearchInput";
import Menu_Desplegable_Izq from "./Header/Menu_Desplegable_Izq";
import Logo from "./Header/Logo";
import Enlaces_Nav from "./Header/Enlaces_Nav";
import Dropdown from "./Header/Dropdown";
import Dropdown_User from "./Header/Dropdown_User";
import Carrito from "./Header/Carrito";
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-light bg-light`}
        style={{
          position: "sticky",
          top: "0",
          zIndex: "999",
        }}
      >
        <div className="container-fluid">
          <Menu_Desplegable_Izq />
          <div className="col">
            <Logo />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
            onClick={handleToggleMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={25}
              height={25}
              fill="currentColor"
              className="bi bi-list-nested"
              viewBox="0 0 16 16"
              style={{ color: "var(--bs-navbar-color)" }}
            >
              <path
                fillRule="evenodd"
                d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarTogglerDemo01"
            style={{ flexGrow: 0 }}
          >
            <ul
              className="navbar-nav  mb-2 mb-lg-0  d-flex justify-content-between"
              style={{ paddingLeft: "10%" }}
            >
              <SearchInput />
              <Enlaces_Nav nombre={"Home"} url="/" />
              <Dropdown />
              <Dropdown_User />
              <Carrito />
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
