import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import AdminMenu from "../../components/AdminMenu";
// import AdminMenu from "../../components/AdminMenu.js";
// import Layout from "../../components/Layout.js";
// import { BASE_URL } from "../../components/Routes/global-env.js";

const Products = () => {
  const [products, setProducts] = useState([]);

  const navigate = useNavigate();

  //get all Products
  const getProducts = async () => {
    try {
      const data = await axios.get(`${BASE_URL}/api/productos`);
      setProducts(data.data.body);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  //delete a product
  const handleDelete = async (id) => {
    await axios.put(`${BASE_URL}/api/productos/${id}`);
    setTimeout(() => {
      toast.success("Product Delete Successfully");
    }, 500);
    getProducts();

    navigate("/dashboard/admin/products");
  };

  useEffect(() => {
    getProducts();
  }, []);
  return (
    <Layout>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <NavLink
              to="/dashboard/admin/create-product"
              className="btn btn-primary btn-lg mt-2 mb-2 text-white text-center"
              style={{ width: "90%", marginLeft: "2%", marginRight: "2%" }}
            >
              Crear Producto
            </NavLink>

            <div className="d-flex">
              <table className="table table-striped">
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Nombre</th>
                    <th>Descripcion</th>
                    <th>Precio</th>
                    <th>Costo</th>
                    <th>Provincia</th>
                    <th>Categoria</th>
                    <th style={{ width: "10px" }}>Foto</th>
                    <th>Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((product) => (
                    <tr key={product.id}>
                      <td> {product.nombre} </td>
                      <td className="td_tabla"
                        style={{
                          // maxLines: 1,
                          // width: "48px",
                          whiteSpace: "pre-line",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        {product.descripcion}{" "}
                      </td>
                      <td> {product.precio} </td>
                      <td> {product.costo} </td>
                      <td> {product.provincia} </td>
                      <td> {product.categoria} </td>
                      <td style={{ maxLines: 1, width: "48px" }}>
                        {" "}
                        {product.imagen}{" "}
                      </td>

                      <td>
                        <Link
                          to={`/dashboard/admin/product/${product.id}`}
                          className="btn btn-warning mr-2"
                        >
                          Editar
                        </Link>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleDelete(product.id)}
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="col-md-9">
            <h1 className="text-center">All Products List</h1>
            <div className="d-flex">
              {products?.map((p) => (
                <>
                  <Link
                    to={`/dashboard/admin/product/${p.id}`}
                    className="product-link"
                  >
                    <div
                      className="card m-2"
                      style={{ width: "18rem" }}
                      key={p.id}
                    >
                      <img
                        src={`${BASE_URL}/api/productos/imagen/${p.id}`}
                        className="card-img-top"
                        alt={p.nombre}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{p.nombre}</h5>
                        <p className="card-text">{p.descripcion}</p>
                      </div>
                    </div>
                  </Link>
                </>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default Products;
