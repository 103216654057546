import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import AdminMenu from "../../components/AdminMenu";
import axios from "axios";
import toast from "react-hot-toast";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
const { Option } = Select;

const UpdateRemesa = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [tipo, setTipo] = useState("");
  const [descripction, setDescripction] = useState("");
  const [price, setPrice] = useState("");
  const [photo, setPhoto] = useState("");

  const [imagen, setImagen] = useState("");

  const [id, setId] = useState("");

  //get single product
  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/remesas/${params.id}`
      );
      setName(data.body[0].nombre);
      setId(params.id);
      setDescripction(data.body[0].descripcion);
      setPrice(data.body[0].precio);
      setTipo(data.body[0].tipo);
      setPhoto(data.body[0].imagen);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getSingleProduct();
  }, []);

  //Update remesa
  const handleUpdate = async (e) => {
    e.preventDefault();
 
    try {
      const productData = new FormData();
      productData.append("nombre", name);
      productData.append("descripcion", descripction);
      productData.append("precio", price);
      productData.append("tipo", tipo);
      if (imagen === "") {
        productData.append("imagen", photo);
      } else {
        productData.append("imagen", imagen);
      }

      const { data } = await axios.post(
        `${BASE_URL}/api/remesas/remesa/${id}`,
        productData
      );
      if (data) {
        setTimeout(() => {
          toast.success("Remesa Actualizada");
        }, 500);
        navigate("/dashboard/admin/remesas");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout title={"Dashborad - Crear Remesa"}>
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1 className="text-center">Editar Remesa</h1>
            <div className="mb-3">
              <input
                placeholder="Nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <input
                placeholder="Descripcion"
                value={descripction}
                onChange={(e) => setDescripction(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <input
                placeholder="Precio"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                type="number"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <Select
                bordered={false}
                placeholder="Selecciona el Tipo de remesas"
                size="large"
                showSearch
                value={tipo}
                className="form-select mb-3"
                onChange={(value) => {
                  setTipo(value);
                }}
              >
                <Option value={"USD"}>USD</Option>
                <Option value={"CUP"}>CUP</Option>
              </Select>
            </div>

            <div className="mb-3 text-center">
              <label className="btn btn-outline-secondary col-md-12">
                {imagen ? imagen.name : photo}
                <input
                  type="file"
                  name="photo"
                  accept="image/*"
                  onChange={(e) => setImagen(e.target.files[0])}
                  hidden
                />
              </label>
            </div>
            <div className="mb-3 text-center">
              {imagen === "" ? (
                <div className="text-center">
                  <img
                    src={`${BASE_URL}/api/remesas/imagen/${params.id}`}
                    alt="product_photo"
                    height={"200px"}
                    className="img img-responsive"
                  />
                </div>
              ) : (
                <div className="text-center">
                  <img
                    src={URL.createObjectURL(imagen)}
                    alt="product_photo"
                    height={"200px"}
                    className="img img-responsive"
                  />
                </div>
              )}
            </div>

            <div className="mb-3 text-center">
              <button className="btn btn-primary" onClick={handleUpdate}>
                Actualizar Remesa
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UpdateRemesa;
