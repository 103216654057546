import React from "react";
import { NavLink } from "react-router-dom";
import useCategory from "../../hooks/useCategoys";

const Menu_Desplegable_Izq = () => {
  const categories = useCategory();
  return (
    <div className="col" style={{ paddingLeft: "25px" }}>
      <div className="col-4" style={{ paddingLeft: "0px" }}>
        <li className="nav-item dropdown" style={{ listStyleType: "none" }}>
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ border: "none" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={25}
                fill="currentColor"
                className="bi bi-list-nested"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5"
                />
              </svg>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink to="/about" style={{ color: "black" }}>
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact" style={{ color: "black" }}>
                    Contact
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Categorias
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                    style={{
                      position: "absolute",
                      borderRadius: "10px",
                    }}
                  >
                    {categories?.map((c, index) => (
                      <li key={index}>
                        <NavLink
                          key={index}
                          className="dropdown-item"
                          to={`/category/${c.nombre}`}
                        >
                          {c.nombre}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </div>
    </div>
  );
};
export default Menu_Desplegable_Izq;
