import React from "react";
import { NavLink } from "react-router-dom";

const Enlaces_Nav = ({ nombre, url }) => {
  return (
    <li className="nav-item">
      <NavLink
        to={`${url}`}
        className="nav-link "
        style={{ paddingBottom: "4px" }}
      >
        {nombre}
      </NavLink>
    </li>
  );
};

export default Enlaces_Nav;
