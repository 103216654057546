import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to={`/`} className="navbar-brand">
      {" "}
      Send<span style={{ fontSize: "11px" }}>Cuba </span>
    </Link>
  );
};

export default Logo;
