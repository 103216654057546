// import Layout from "../components/Layout.js";
import React from "react";
import Layout from "../../Layout/Layout.js";
import DetallesPedido from "../../Pedidos/DetallesPedidos.js";
const PedidosIDPage = () => {
  return (
    <Layout title={"Pedidos"}>
      <div className="container-fluid " style={{ padding: "0px" }}>
        <h1 className="bg-dark text-white text-center">Detalles del Pedido</h1>
        <DetallesPedido />
      </div>
    </Layout>
  );
};

export default PedidosIDPage;
