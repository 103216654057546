import React from "react";
import { NavLink } from "react-router-dom";
import useCategory from "../../hooks/useCategoys.js";

const Menu_Desplegable_Izq = () => {
  const categories = useCategory();
  return (
    <div className="col">
      <ul className="nav__list">
        <li>
          <a
            to={"/categories"}
            role="button"
            style={{ fontSize: "16px", content: "none" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={25}
              height={25}
              fill="currentColor"
              className="bi bi-list-nested"
              viewBox="0 0 16 16"
              style={{ color: "var(--bs-navbar-color)" }}
            >
              <path
                fillRule="evenodd"
                d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </a>
          <ul>
            <li>
              <NavLink to="/about" style={{ color: "black" }}>
                About
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" style={{ color: "black" }}>
                Contact
              </NavLink>
            </li>
            <li>
              <a>Categorias</a>
              <ul>
                {categories?.map((c, index) => (
                  <li key={index}>
                    <NavLink
                      key={index}
                      className="dropdown-item"
                      to={`/category/${c.nombre}`}
                    >
                      {c.nombre}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Menu_Desplegable_Izq;
