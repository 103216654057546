import { useContext } from "react";

import { CartContext } from "./cart_Context.js";

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("UseCart Error");
  }
  return context;
};
