import React, { useEffect, useState } from "react";
// import Layout from "../../components/Layout.js";
// import AdminMenu from "../../components/AdminMenu.js";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import AdminMenu from "../../components/AdminMenu";
// import { BASE_URL } from "../../components/Routes/global-env.js";
const Users = () => {
  const [usuarios, setUser] = useState([]);

  const navigate = useNavigate();

  //get all usuarios
  const getusuarios = async () => {
    try {
      const data = await axios.get(`${BASE_URL}/api/usuarios`);
      setUser(data.data.body);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  //delete a product
  const handleDelete = async (id) => {
    await axios.put(`${BASE_URL}/api/usuarios/${id}`);
    setTimeout(() => {
      toast.success("Product Delete Successfully");
    }, 500);
    getusuarios();

    navigate("/dashboard/admin/users");
  };

  useEffect(() => {
    getusuarios();
  }, []);
  return (
    <Layout title={"Dashborad - All Users"}>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1>Todos los Usuarios</h1>
            <div className="d-flex">
              <table className="table table-striped">
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Telefono</th>
                    <th>Admin</th>
                    <th>Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {usuarios?.map((user) => (
                    <tr key={user.id}>
                      <td> {user.nombre} </td>
                      <td style={{ maxLines: 1, width: "48px" }}>
                        {" "}
                        {user.email}{" "}
                      </td>
                      <td> {user.telefono} </td>
                      <td> {user.admin} </td>

                      <td>
                        <Link
                          to={`/dashboard/admin/user/${user.id}`}
                          className="btn btn-warning mr-2"
                        >
                          Editar
                        </Link>

                        <button
                          className="btn btn-danger"
                          onClick={() => handleDelete(user.id)}
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Users;
