
import { useReducer, createContext } from "react";
import { cartReducer, cartInitialState } from "./reducers/cart.js";

export const CartContext = createContext();

function useCartReducer() {
  const [state, dispatch] = useReducer(cartReducer, cartInitialState);

  const addToCart = (product) =>
    dispatch({
      type: "ADD_TO_CART",
      payload: product,
    });

  const removeFromCart = (product) =>
    dispatch({
      type: "REMOVE_FROM_CART",
      payload: product,
    });
  const restToCart = (product) =>
    dispatch({
      type: "REST_ONE_TO_CART",
      payload: product,
    });
  const sumaToCart = (product) =>
    dispatch({
      type: "SUMA_ONE_TO_CART",
      payload: product,
    });

  const clearCart = () => dispatch({ type: "CLEAR_CART" });

  return {
    state,
    addToCart,
    removeFromCart,
    sumaToCart,
    restToCart,
    clearCart,
  };
}

// la dependencia de usar React Context
// es MÍNIMA
export function CartProvider({ children }) {
  const {
    state,
    addToCart,
    removeFromCart,
    sumaToCart,
    restToCart,
    clearCart,
  } = useCartReducer();

  return (
    <CartContext.Provider
      value={{
        cart: state,
        addToCart,
        removeFromCart,
        clearCart,
        restToCart,
        sumaToCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
