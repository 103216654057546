// import Layout from "../components/Layout.js";
import React from "react";
import Layout from "../../Layout/Layout";
import AllPedidos from "../../Pedidos/AllPedidos";
const PedidosPage = () => {
  return (
    <Layout title={"Pedidos"}>
      <div className="container-fluid " style={{ padding: "0px" }}>
        <h1 className="bg-dark text-white text-center">Pedidos Realizados</h1>
        <div className="row gx-4 gx-lg-5 row-cols-1 row-cols-md-2 row-cols-xl-2 justify-content-center">
          <AllPedidos />
        </div>
      </div>
    </Layout>
  );
};

export default PedidosPage;
