import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CartPage from "./pages/CartPage";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Policy from "./pages/Policy";
import Pagenotfound from "./pages/Pagenotfound";
import Search from "./pages/Demas_Rutas/Search";
import PedidosPage from "./pages/Demas_Rutas/PedidosPage";
import PedidosIDPage from "./pages/Demas_Rutas/Pedidos_ID_Page ";
import CategoryList from "./pages/Demas_Rutas/CategoryList";
import ProvinciaList from "./pages/Demas_Rutas/ProvinciaList ";
import ProductDetails from "./pages/Demas_Rutas/ProductDetails";
import AdminRoute from "./Routes/AdminRoute";
import AdminDashborad from "./pages/Admin/AdminDashborad";
import CreateCategory from "./pages/Admin/CreateCategory";
import CreateProvincia from "./pages/Admin/CreateProvincia";
import CreateProduct from "./pages/Admin/CreateProduct";
import CreateRemesa from "./pages/Admin/CreateRemesa";
import Products from "./pages/Admin/Products";
import Remesas from "./pages/Admin/Remesas";
import Pedidos from "./pages/Admin/Pedidos";
import Users from "./pages/Admin/Users";
import UpdateRemesa from "./pages/Admin/UpdateRemesa";
import UpdateUser from "./pages/Admin/UpdateUser";
import PedidosIDPageAdmin from "./pages/Admin/PedidosIDPageAdmin";
import UpdateProducts from "./pages/Admin/UpdateProducts";
import UpdatePedido from "./pages/Admin/UpdatePedido";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="*" element={<Pagenotfound />} />
        {/* Mas Rutas */}
        <Route path="/search" element={<Search />} />
        <Route path="/pedidos" element={<PedidosPage />} />
        <Route path="/pedidos/pedido/:id" element={<PedidosIDPage />} />
        <Route path="/category/:keyword" element={<CategoryList />} />
        <Route path="/provincia/:keyword" element={<ProvinciaList />} />
        <Route path="/product/:id" element={<ProductDetails />} />
        {/* Admin Routes */}
        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashborad />} />
          <Route path="admin/create-category" element={<CreateCategory />} />
          <Route path="admin/create-provincia" element={<CreateProvincia />} />
          <Route path="admin/create-product" element={<CreateProduct />} />
          <Route path="admin/products" element={<Products />} />
          <Route path="admin/remesas" element={<Remesas />} />
          <Route path="admin/pedidos" element={<Pedidos />} />
          <Route path="admin/users" element={<Users />} />
          <Route path="admin/create-remesa" element={<CreateRemesa />} />
          <Route path="admin/remesa/:id" element={<UpdateRemesa />} />
          <Route path="admin/user/:id" element={<UpdateUser />} />
          <Route path="admin/pedidos/:id" element={<PedidosIDPageAdmin />} />
          <Route path="admin/product/:slug" element={<UpdateProducts />} />
          <Route path="admin/pedido/:id" element={<UpdatePedido />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
