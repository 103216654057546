import React, { useEffect, useState } from "react";
// import Layout from "../../components/Layout.js";
// import AdminMenu from "../../components/AdminMenu.js";
import axios from "axios";
import toast from "react-hot-toast";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import AdminMenu from "../../components/AdminMenu";
// import { BASE_URL } from "../../components/Routes/global-env.js";
const { Option } = Select;

const CreateProduct = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [costo, setCosto] = useState("");
  const [provincia, setProvincia] = useState([]);
  const [slug, setSlug] = useState("");

  const [prov, setProv] = useState("");
  const [descripction, setDescripction] = useState("");
  const [price, setPrice] = useState("");
  const [photo, setPhoto] = useState("");

  //get all categories
  const getAllCategories = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/categorias`);
      if (data.body) {
        setCategories(data.body);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting category");
    }
  };
  const getAllProvincia = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/provincias`);
      if (data.body) {
        setProvincia(data.body);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting category");
    }
  };

  useEffect(() => {
    getAllCategories();
    getAllProvincia();
  }, []);

  //create product function
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const productData = new FormData();
      productData.append("nombre", name);
      productData.append("descripcion", descripction);
      productData.append("precio", price);
      productData.append("categoria", slug);
      productData.append("imagen", photo);
      productData.append("costo", costo);
      productData.append("provincia", prov);
      const { data } = await axios.post(
        `${BASE_URL}/api/productos/`,
        productData
      );
      if (data.nombre) {
        toast.success("Product Created Successfully");
        navigate("/dashboard/admin/products");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout title={"Dashborad - Create Product"}>
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          {/* <div className="col-md-9">
            <h1 className="text-center">Create Product</h1>
            <div className="m-1 w-75">
              <Select
                bordered={false}
                placeholder="Select a category"
                size="large"
                showSearch
                className="form-select mb-3"
                onChange={(value) => {
                  setCategory(value);
                }}
              >
                {categories?.map((c) => (
                  <Option key={c.id} value={c.id}>
                    {c.nombre}
                  </Option>
                ))}
              </Select>
              <Select
                bordered={false}
                placeholder="Select a category"
                size="large"
                showSearch
                className="form-select mb-3"
                onChange={(value) => {
                  setSlug(value);
                }}
              >
                {categories?.map((c) => (
                  <Option key={c.id} value={c.id}>
                    {c.nombre}
                  </Option>
                ))}
              </Select>
              <div className="mb-3 text-center">
                <label className="btn btn-outline-secondary col-md-12">
                  {photo ? photo.name : "Upload Photo"}
                  <input
                    type="file"
                    name="photo"
                    accept="image/*"
                    onChange={(e) => setPhoto(e.target.files[0])}
                    hidden
                  />
                </label>
              </div>
              <div className="mb-3 text-center">
                {photo && (
                  <div className="text-center">
                    <img
                      src={URL.createObjectURL(photo)}
                      alt="product_photo"
                      height={"200px"}
                      className="img img-responsive"
                    />
                  </div>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  value={name}
                  placeholder="Write a Name"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <textarea
                  type="text"
                  value={descripction}
                  placeholder="Write a descripction"
                  className="form-control"
                  onChange={(e) => setDescripction(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  value={price}
                  placeholder="Write a Price"
                  className="form-control"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  value={costo}
                  placeholder="Write a Price"
                  className="form-control"
                  onChange={(e) => setCosto(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  value={quantity}
                  placeholder="Write a quantity"
                  className="form-control"
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <Select
                  bordered={false}
                  size="large"
                  showSearch
                  placeholder="Select Shipping"
                  className="form-select mb-3"
                  onChange={(value) => setShipping(value)}
                >
                  <Option value="0">No</Option>
                  <Option value="1">Si</Option>
                </Select>
              </div>
              <div className="mb-3 text-center">
                <button className="btn btn-primary" onClick={handleCreate}>
                  CREATE PRODUCT
                </button>
              </div>
            </div>
          </div> */}
          <div className="col-md-9">
            <h1 className="text-center">Crear Producto</h1>
            {/* <form> */}
            <div className="mb-3">
              <input
                placeholder="Nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <textarea
                placeholder="Descripcion"
                value={descripction}
                onChange={(e) => setDescripction(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <input
                placeholder="Precio"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                type="number"
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <input
                placeholder="Costo"
                value={costo}
                onChange={(e) => setCosto(e.target.value)}
                type="number"
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <Select
                bordered={false}
                placeholder="Selecciona la Categoria"
                size="large"
                showSearch
                className="form-select mb-3"
                onChange={(value) => {
                  setSlug(value);
                }}
              >
                {categories?.map((c) => (
                  <Option key={c.id} value={c.nombre}>
                    {c.nombre}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="mb-3">
              <Select
                bordered={false}
                placeholder="Selecciona la Provincia"
                size="large"
                showSearch
                className="form-select mb-3"
                onChange={(value) => {
                  setProv(value);
                }}
              >
                {provincia?.map((c) => (
                  <Option key={c.id} value={c.nombre}>
                    {c.nombre}
                  </Option>
                ))}
              </Select>
            </div>
            {/* <div className="mb-3">
              <input
                placeholder="Provincia"
                value={provincia}
                onChange={(e) => setProvincia(e.target.value)}
                type="number"
                className="form-control"
              />
            </div> */}

            <div className="mb-3 text-center">
              <label className="btn btn-outline-secondary col-md-12">
                {photo ? photo.name : "Upload Photo"}
                <input
                  type="file"
                  name="photo"
                  accept="image/*"
                  onChange={(e) => setPhoto(e.target.files[0])}
                  hidden
                />
              </label>
            </div>
            <div className="mb-3 text-center">
              {photo && (
                <div className="text-center">
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="product_photo"
                    height={"200px"}
                    className="img img-responsive"
                  />
                </div>
              )}
            </div>

            <div className="mb-3 text-center">
              <button className="btn btn-primary" onClick={handleCreate}>
                CREATE PRODUCT
              </button>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CreateProduct;
