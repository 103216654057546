import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { BASE_URL } from "../Routes/global-env";
import { useCart } from "../Carrito/use_Cart_Hooks";
import ModalCambioProvincia from "./ModalCambioProvincia";
import ModalSelectProvincia from "./ModalSelectProvincia";

const DetallesProductos = ({ producto }) => {
  const { addToCart } = useCart();

  const [cantidad, setCantidad] = useState(1);
  const [provinciaError, setProvinciaError] = useState(false);
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  const [productoModal, setProducto] = useState();

  useEffect(() => {
    setProvinciaSeleccionada(localStorage.getItem("provincia"));
  }, [provinciaSeleccionada]);
  return (
    <>
      {producto.map((p) => (
        <MDBCard key={p.id}>
          <MDBRow className="g-0">
            <MDBCol md="4">
              <MDBCardImage
                src={`${BASE_URL}/api/productos/imagen/${p.id}`}
                className="card-img-top imagenes"
                alt={p.nombre}
                fluid
                style={{
                  position: "relative",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </MDBCol>
            <MDBCol md="8">
              <MDBCardBody>
                <MDBCardTitle>Nombre: {p.nombre}</MDBCardTitle>
                <MDBCardText>
                  Description:
                  <p
                    className="card-text"
                    dangerouslySetInnerHTML={{
                      __html: p.descripcion.replace(/\r\n/g, "<br>"),
                    }}
                  ></p>
                </MDBCardText>
                {/* <MDBCardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </MDBCardText> */}
                <MDBRow style={{ marginBottom: "10px" }}>
                  <MDBCol md="5" className="col-5">
                    <MDBCardText>Provincia: {p.provincia}</MDBCardText>
                  </MDBCol>
                  <MDBCol md="5" className="col-5">
                    <div className="input-group">
                      <button
                        style={{ paddingLeft: "7px" }}
                        className="btn btn-default"
                        onClick={() => {
                          if (cantidad > 1) {
                            setCantidad((p.cantidad = p.cantidad - 1));
                          }
                        }}
                      >
                        -
                      </button>

                      <input
                        type="text"
                        className="form-control"
                        value={p.cantidad}
                        disabled
                        readOnly
                        onChange={() => setCantidad(p.cantidad)}
                        style={{ maxWidth: "100px" }}
                      />

                      <button
                        style={{ paddingRight: "7px" }}
                        className="btn btn-default"
                        onClick={() =>
                          setCantidad((p.cantidad = p.cantidad + 1))
                        }
                      >
                        +
                      </button>
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow style={{ marginBottom: "5px" }}>
                  <MDBCol md="3" className="col-3">
                    <MDBCardText>$ {p.precio}</MDBCardText>
                  </MDBCol>
                  <MDBCol md="9" className="col-9">
                    <MDBBtn
                      style={{
                        outline: "none",
                        transform: "scale(1)",
                        height: "40px", // Establece una altura fija en píxeles
                        width: "100%",
                      }}
                      rounded
                      color="success"
                      rippleColor="dark"
                      size="md"
                      onClick={() => {
                        if (provinciaSeleccionada === null) {
                          addToCart(p);
                          localStorage.setItem("provincia", p.provincia);
                        } else {
                          if (provinciaSeleccionada === p.provincia) {
                            addToCart(p);
                          } else {
                            setProvinciaError(true);
                            setProducto(p);
                          }
                        }
                      }}
                    >
                      Añadir al Carrito
                      <svg
                        style={{ marginLeft: "10px" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width={21}
                        height={21}
                        fill="currentColor"
                        className="bi bi-cart-check-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708" />
                      </svg>
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      ))}
      <ModalCambioProvincia
        isOpen={provinciaError}
        producto={productoModal}
        url={true}
        onClose={() => setProvinciaError(false)}
      />
      <ModalSelectProvincia
        isOpen={provinciaSeleccionada === null}
        onClose={() => setProvinciaError(false)}
      />
    </>
  );
};
export default DetallesProductos;
