import React from "react";
import { useAuth } from "../../context/auth";
import Layout from "../../Layout/Layout";
import AdminMenu from "../../components/AdminMenu";

const AdminDashborad = () => {
  const [auth] = useAuth();
  return (
    <Layout>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3 text-center" >
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <div className="card w-75 p-2">
              <h2 className="text-center">Detalls Admin </h2>
              <h3>Nombre: {auth?.user}</h3>
              <h3>Email: {auth?.email}</h3>
              <h3>Contact: {auth?.telefono}</h3>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashborad;
