import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { useCart } from "../Carrito/use_Cart_Hooks";
import { useNavigate } from "react-router-dom";

const ModalSelectProvincia = ({ isOpen, onClose, producto, url }) => {
  const { addToCart, clearCart } = useCart();

  const navigate = useNavigate();
  return (
    <>
      <MDBModal open={isOpen} tabIndex="-1" staticBackdrop>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Atención </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={onClose}
              ></MDBBtn>
            </MDBModalHeader>

            <MDBModalBody>
              Va añadir un producto de una provincia distinta a la de los
              productos que ya tiene en el carrito. Seleccione la opcion
              deseada:
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                style={{ maxWidth: " 65px", maxHeight: "37px" }}
                color="secondary"
                onClick={onClose}
              >
                Cerrar
              </MDBBtn>
              <MDBBtn
                style={{ maxWidth: "176px", maxHeight: "37px" }}
                color="success"
                onClick={() => {
                  clearCart();
                  addToCart(producto);
                  localStorage.setItem("provincia", producto.provincia);
                  onClose();
                  // window.location.reload();
                  if (url) {
                    navigate(`/provincia/${producto.provincia}`);
                  }
                }}
              >
                Cambiar de provincia
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};
export default ModalSelectProvincia;
