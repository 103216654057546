import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import AdminMenu from "../../components/AdminMenu";
import axios from "axios";
import toast from "react-hot-toast";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
const { Option } = Select;

const UpdateUser = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [admin, setAdmin] = useState("");

  const [id, setId] = useState("");

  //get single product
  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/usuarios/${params.id}`
      );
      setName(data.body[0].email);
      setId(params.id);
      setAdmin(data.body[0].admin);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProduct();
  }, []);

  //create product function
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/usuarios/user/${id}`,
        { admin: admin }
      );
      if (data) {
        setTimeout(() => {
          toast.success("Usuario Actualizado");
        }, 500);
        navigate("/dashboard/admin/users");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout title={"Dashborad - Crear Remesa"}>
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1 className="text-center">Editar Usuario</h1>
            <div className="mb-3">
              <input
                placeholder="Email"
                value={name}
                // onChange={(e) => setName(e.target.value)}
                disabled
                type="text"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <Select
                bordered={false}
                placeholder="Selecciona el Tipo de remesas"
                size="large"
                showSearch
                value={admin}
                className="form-select mb-3"
                onChange={(value) => {
                  setAdmin(value);
                }}
              >
                <Option value={"SI"}>SI</Option>
                <Option value={"NO"}>NO</Option>
              </Select>
            </div>

            <div className="mb-3 text-center">
              <button className="btn btn-primary" onClick={handleCreate}>
                Actualizar User
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UpdateUser;
