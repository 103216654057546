import React from 'react'
import Layout from '../Layout/Layout'
const About = () => {
    return (
        <Layout title={'About us - Ecommer app'}>
            <div className='page-container'>
                <div className='row contactus'>
                    <div className='col-md-6'>
                        <img src='images/aboutUs.jpg' alt='About Us' style={{ width: "100%" }} />

                    </div>
                    <div className='col-md-4'>
                        <p>Bienvenido a nuestro Ecommerce App, donde encontrarás una amplia variedad de artículos y productos de alta calidad. Nos apasiona brindarte una experiencia de compra excepcional y ofrecerte los productos más buscados. Creemos en la calidad y autenticidad de nuestros productos, trabajando con proveedores confiables y marcas reconocidas. Tu satisfacción es nuestra prioridad, por eso hemos diseñado nuestro sitio web de forma intuitiva para que puedas navegar fácilmente y realizar compras sin complicaciones. Explora nuestro Ecommerce App y descubre una amplia gama de productos emocionantes. ¡Gracias por elegirnos!</p>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default About