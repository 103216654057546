import React from "react";
import { useCart } from "./use_Cart_Hooks";
import { Link } from "react-router-dom";
import { BASE_URL } from "../Routes/global-env";

const AllProductsCarrito = () => {
  const { cart, removeFromCart, restToCart, sumaToCart } = useCart();
  return (
    // <div className="row">
      <div className="col-md-8">
        <section className="">
          {cart?.map((p, index) => (
            <div className="card-cart mb-4" key={index}>
              <div className="card-image-cart mt-2 mb-2">
                <Link style={{ color: "black" }} to={`/product/${p.id}`}>
                  <img
                    className="img-cart-card"
                    src={`${BASE_URL}/api/productos/imagen/${p.id}`}
                    alt={p.nombre}
                  />
                </Link>
              </div>

              <div className="card-content-cart mt-2 mb-2">
                <Link style={{ color: "black" }} to={`/product/${p.id}`}>
                  <h5 className="fw-bolder">{p.nombre}</h5>
                  <h6>Precio: ${p.precio}</h6>
                </Link>

                <div
                  className="row align-items-end justify-content-end"
                  style={{ marginTop: "45px" }}
                >
                  <div className="col">
                    <div
                      className="input-group"
                      style={{ maxWidth: "140px", minWidth: "100px" }}
                    >
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          if (p.quantity > 1) {
                            restToCart(p);
                          }
                        }}
                      >
                        -
                      </button>

                      <input
                        type="text"
                        className="form-control"
                        value={p.quantity}
                        disabled
                        readOnly
                      />

                      <button
                        className="btn btn-default"
                        onClick={() => {
                          sumaToCart(p);
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="col " style={{ marginLeft: "5px" }}>
                    <button
                      className="btn btn-danger"
                      onClick={() => removeFromCart(p)}
                    >
                      {" "}
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
      
    // </div>
  );
};

export default AllProductsCarrito;
