import React, { useEffect, useState } from "react";
import { useAuth } from "../context/auth";
import { useNavigate, Link } from "react-router-dom";
import { BASE_URL } from "../Routes/global-env";
import toast from "react-hot-toast";
import axios from "axios";

const AllPedidos = () => {
  const [pedidos, setPedidos] = useState([]);

  const [auth] = useAuth();

  const navigate = useNavigate();

  //get all pedidos
  const getAllPedidos = async () => {
    try {
      if (auth.email) {
        const { data } = await axios.get(
          `${BASE_URL}/api/pedidos/${auth.email}`
        );
        if (data.body) {
          setPedidos(data.body);
        }
      }
    } catch (error) {
      toast.error("Something went wrong in getting category");
    }
  };
  const handleDelete = async (id) => {
    await axios.post(`${BASE_URL}/api/pedidos/mostrar/${id}`);
    setTimeout(() => {
      toast.success("Pedido Eliminado");
    }, 500);
    getAllPedidos();

    navigate("/pedidos");
  };

  useEffect(() => {
    getAllPedidos();
  }, [auth]);

  function formatDate(date) {
    const formattedDate = new Date(date);
    const day = formattedDate.getDate();
    const month = formattedDate.getMonth() + 1;
    const year = formattedDate.getFullYear();
    return `${day}/${month}/${year}`;
  }
  return (
    <>
      {pedidos?.map((pedido, index) => (
        <div key={index}>
          {pedido.mostrar !== "NO" ? (
            <div
              className="card"
              style={{ padding: "10px", margin: "10px" }}
              key={index}
            >
              <Link style={{ color: "black" }} to={`/pedido/${pedido.id}`}>
                <div className="card-header">
                  <div className="container">
                    <div className="row row-cols-2">
                      <div className="col">Numero del Pedido: {pedido.id}</div>
                      <div className="col">Estado: {pedido.estado}</div>
                    </div>
                  </div>
                </div>
              </Link>
              <div className="card-body">
                <Link style={{ color: "black" }} to={`pedido/${pedido.id}`}>
                  <h5 className="card-title">
                    <div className="container">
                      <div className="row row-cols-2">
                        <div className="col">
                          Lo recibe: {pedido.nombre_recibe}
                        </div>
                        <div className="col">
                          Fecha: {formatDate(pedido.fecha)}
                        </div>
                      </div>
                    </div>
                  </h5>
                  <p className="card-text">
                    {pedido.descripcion.split(",").map((desc) => (
                      <p>
                        {desc.replace(
                          /[`!"#$%&'*+,\-./;<=>?@[\\\]^_`{|}~]/g,
                          ""
                        )}
                      </p>
                    ))}
                  </p>
                </Link>
                <div className="row row-cols-2">
                  <div className="col">Precio: {pedido.total_precio}</div>
                  <div className="col">
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(pedido.id)}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ))}
    </>
  );
};

export default AllPedidos;
