import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import AdminMenu from "../../components/AdminMenu";
const Pedidos = () => {
  const [pedidos, setPedidos] = useState([]);

  const navigate = useNavigate();

  //get all pedidos
  const getAllPedidos = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/pedidos`);
      if (data.body) {
        setPedidos(data.body);
      }
    } catch (error) {
      toast.error("Something went wrong in getting category");
    }
  };
  const handleDelete = async (id) => {
    await axios.put(`${BASE_URL}/api/pedidos/${id}`);
    setTimeout(() => {
      toast.success("Product Delete Successfully");
    }, 500);
    getAllPedidos();

    navigate("/dashboard/admin/pedidos");
  };

  useEffect(() => {
    getAllPedidos();
  }, []);

  function formatDate(date) {
    const formattedDate = new Date(date);

    const day = formattedDate.getDate();
    const month = formattedDate.getMonth() + 1;
    const year = formattedDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  return (
    <Layout title={"Dashborad - Create Provincia"}>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1 className="bg-dark p-2 text-white text-center">
              Todos los Pedidos{" "}
            </h1>

            <div className="row gx-4 gx-lg-5 row-cols-1 row-cols-md-2 row-cols-xl-2 justify-content-center">
              {pedidos?.map((pedido) => (
                <div>
                  <div className="card" style={{ padding: "10px" }}>
                    <Link
                      style={{ color: "black" }}
                      to={`/dashboard/admin/pedidos/${pedido.id}`}
                    >
                      <div className="card-header">
                        <div className="container">
                          <div className="row row-cols-2">
                            <div className="col">
                              Numero del Pedido: {pedido.id}
                            </div>
                            <div className="col">Estado: {pedido.estado}</div>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="card-body">
                      <Link
                        style={{ color: "black" }}
                        to={`/dashboard/admin/pedidos/${pedido.id}`}
                      >
                        <h5 className="card-title">
                          <div className="container">
                            <div className="row row-cols-2">
                              <div className="col">
                                Lo recibe: {pedido.nombre_recibe}
                              </div>
                              <div className="col">
                                Fecha: {formatDate(pedido.fecha)}
                              </div>
                            </div>
                          </div>
                        </h5>
                        <p className="card-text">
                          {pedido.descripcion.split(",").map((desc) => (
                            <p>
                              {desc.replace(
                                /[`!"#$%&'*+,\-./;<=>?@[\\\]^_`{|}~]/g,
                                ""
                              )}
                            </p>
                          ))}
                        </p>
                      </Link>
                      <div className="row row-cols-2">
                        <div className="col">Precio: {pedido.total_precio}</div>
                        <div className="col">
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(pedido.id)}
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Pedidos;
