import React from "react";
import { MDBRow } from "mdb-react-ui-kit";
import Layout from "../../Layout/Layout.js";
import { useSearch } from "../../context/Search.js";
import MenuDesplegableCategoria from "../../components/Menu_Desplegable_Categoria.js";
import DetallesProductos from "../../Products/Detalles_Producto.js";
import AllProductsSearch from "../../Products/AllProductsSearch.js";
const Search = () => {
  const [values] = useSearch();
  return (
    <Layout tittle={"Search results"}>
      <MenuDesplegableCategoria />
      <div className="container">
        <div className="text-center">
          <h1>Resultados de la Busquedad</h1>
          <h6>
            {values?.results.length < 1
              ? "No existen coincidencias"
              : `${values?.results.length} Productos encontrados`}
          </h6>
          <MDBRow
            className="row home"
            style={{ paddingRight: "0px", margin: "1%" }}
          >
            {/* <div className="d-flex flex-wrap mt-4"> */}
            {values.results.length === 1 ? (
              <DetallesProductos producto={values?.results} />
            ) : (
              <AllProductsSearch search={values?.results} />
            )}
          </MDBRow>
        </div>
      </div>
    </Layout>
  );
};

export default Search;
