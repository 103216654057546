import React, {useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { MDBRow } from "mdb-react-ui-kit";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import MenuDesplegableCategoria from "../../components/Menu_Desplegable_Categoria";
import DetallesProductos from "../../Products/Detalles_Producto";
import AllProductsSearch from "../../Products/AllProductsSearch";

const ProvinciaList = () => {
  const params = useParams();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState(params.keyword);
  const getProductsByCat = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/productos/producto-provincia/${params.keyword}`
      );
      setProducts(data?.body);
      setCategory(params.keyword);
    } catch (error) {
      setProducts([]);
    }
  };

  getProductsByCat();

  return (
    <Layout>
      <MenuDesplegableCategoria />
      <div className="container">
        <h4 className="text-center">Provincia - {category}</h4>
        {/* <h6 className="text-center">{products?.length} result found</h6> */}
        {products?.length > 1 ? (
          <h6 className="text-center">{products?.length} productos</h6>
        ) : null}
        {products?.length === 1 ? (
          <h6 className="text-center">{products?.length} producto</h6>
        ) : null}
        {products?.length <= 0 ? (
          <h6 className="text-center">No tenemos productos momentaneamente</h6>
        ) : null}
      </div>
      <MDBRow
        className="row home"
        style={{ paddingRight: "0px", margin: "1%" }}
      >
        {products.length === 1 ? (
          <DetallesProductos producto={products} />
        ) : (
          <AllProductsSearch search={products} />
        )}
      </MDBRow>
    </Layout>
  );
};

export default ProvinciaList;
