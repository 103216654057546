import React from "react";
import SearchInput from "../Form/SearchInput.js";
import Menu_Desplegable_Izq from "./Header_Mobil/Menu_Desplegable_Izq.js";
import Logo from "./Header/Logo.js";
import Dropdwon from "./Header_Mobil/Dropdown_User.js";
import Carrito from "./Header_Mobil/Carrito.js";
import Dropdown_Provincia from "./Header_Mobil/Dropdown_Provincias.js";
const Header_Mobil = () => {
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-light bg-light`}
        style={{
          position: "sticky",
          top: "0",
          zIndex: "999",
        }}
      >
        <div className="container-fluid">
          <div
            className="row row-cols-3 justify-content-between"
            style={{ width: "100%" }}
          >
            <Menu_Desplegable_Izq />
            <div className="col">
              <Logo />
            </div>

            <div className="col">
              <div className="row justify-content-center">
                <Dropdwon />
                <Carrito />
              </div>
            </div>
          </div>
          <div
            className="row row-cols-2"
            style={{ width: "100%", paddingTop: "5px" }}
          >
            <div className="col-6" style={{ width: "60%" }}>
              <SearchInput />
            </div>

            <Dropdown_Provincia />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header_Mobil;
