import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSearch } from "../context/Search";
import { BASE_URL } from "../Routes/global-env";

function SearchInput() {
  const [values, setValues] = useSearch();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/productos/producto-search/${values.keyword}`
      );
      setValues({ ...values, results: data.body });
      navigate("/search");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={{ alignItems:"center", display:"flex", justifyContent:"center",marginLeft:"20px"}}>
      <form className="d-flex" role="search" onSubmit={handleSubmit}>
        <input
          className="form-control me-2"
          type="search"
          placeholder="Buscar"
          aria-label="Search"
          value={values.keyword}
          onChange={(e) => setValues({ ...values, keyword: e.target.value })}
        />
        <button className="btn btn-outline-dark" type="submit" style={{paddingLeft:"10px", paddingRight:"10px"}}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>
          {/* Search */}
        </button>
      </form>
    </div>
  );
}

export default SearchInput;
