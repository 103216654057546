import React, { useState } from "react";
import { useCart } from "./use_Cart_Hooks";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import toast from "react-hot-toast";
import { BASE_URL } from "../Routes/global-env";
import axios from "axios";

const DatosAdicionales = () => {
  const { cart, clearCart } = useCart();
  const [auth] = useAuth();
  const totalPrice = () => {
    try {
      let total = 0;
      cart?.forEach((item) => {
        total = total + item.precio * item.quantity;
      });
      return total.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const totalcosto = () => {
    try {
      let total = 0;
      cart?.forEach((item) => {
        total = total + item.costo * item.quantity;
      });
      return total.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const [basicModal, setBasicModal] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);
  const [pedido, setPedido] = useState([]);
  const [nombre_recibe, setNombre_recibe] = useState("");
  const [direccion_recibe, setDireccion_recibe] = useState("");
  const [telefono_recibe, setTelefono_recibe] = useState("");
  const [aclaracion, setAclaracion] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar campos
    if (nombre_recibe && direccion_recibe && telefono_recibe) {
      // Campos válidos, abrir modal
      handlePayment();
      setBasicModal(true);
    } else {
      // Campos inválidos
      e.preventDefault();
      e.stopPropagation();
      setFormValidated(true);
    }
  };

  const handlePayment = async () => {
    const descripcion = [];
    for (let index = 0; index < cart.length; index++) {
      const texto = `Producto: ${cart[index].nombre} Precio: ${cart[index].precio} Cantidad: ${cart[index].quantity}.`;
      descripcion.push(texto);
    }

    try {
      const { data } = await axios.post(`${BASE_URL}/api/pedidos/`, {
        email: auth.email,
        nombre_envia: auth.user,
        telefono_envia: auth.telefono,
        nombre_recibe: nombre_recibe,
        direccion_recibe: direccion_recibe,
        telefono_recibe: telefono_recibe,
        aclaracion: aclaracion,
        descripcion: JSON.stringify(descripcion),
        total_costo: totalcosto(),
        total_precio: totalPrice(),
        estado: "Solicitado",
      });
      console.log(data);
      if (data.error === false) {
        setBasicModal(true);
        setPedido(data);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const OK = () => {
    if (pedido) {
      setTimeout(() => {
        toast.success("Solicitud Realizada");
      }, 1000);
      clearCart();
      const url = `https://api.whatsapp.com/send?phone=16898880135&text=Buenas,deseo realizar la compra, cuyo id del pedido es ${
        pedido.body.insertId
      }; a nombre de ${auth.email}
      
              *Total a pagar: ${totalPrice()} USD*`;

      const urlDev = encodeURI(url);
      setBasicModal(!basicModal);
      window.open(urlDev);

      navigate("/pedidos");
    } else {
      setTimeout(() => {
        toast.error("Error al solicitar el Pedido");
      }, 1000);
    }
  };
  return (
    <div className="col-md-4 text-center">
      <hr />
      <h4>Datos Adicionales:</h4>
      {/* <p>Total | checkout | Payment</p> */}
      <hr />
      <h4>
        Total:
        {totalPrice()}
      </h4>

      <div className="mb-3">
        {auth?.token ? (
          <>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  placeholder="Nombre del que Recibe"
                  value={nombre_recibe}
                  onChange={(e) => setNombre_recibe(e.target.value)}
                  type="text"
                  required
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <input
                  required
                  placeholder="Direccion a Enviar"
                  value={direccion_recibe}
                  onChange={(e) => setDireccion_recibe(e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <input
                  required
                  placeholder="Telefeno a Contactar"
                  value={telefono_recibe}
                  onChange={(e) => setTelefono_recibe(e.target.value)}
                  type="number"
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <input
                  placeholder="Aclaracion"
                  value={aclaracion}
                  onChange={(e) => setAclaracion(e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>

              <MDBBtn
                style={{ maxWidth: " 94.2px", maxHeight: "37px" }}
                color="success"
                type="submit"
                // onClick={handlePayment}
              >
                Confirmar
              </MDBBtn>
            </form>

            <MDBModal
              open={basicModal || formValidated}
              setOpen={setBasicModal}
              tabIndex="-1"
            >
              <MDBModalDialog centered>
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle>Redireccionando a WhatsApp</MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={toggleOpen}
                    ></MDBBtn>
                  </MDBModalHeader>

                  <MDBModalBody>
                    Usted va hacer redireccionado a WhatsApp, para que realice
                    el pago correspondiente y su pedido sea procesado
                  </MDBModalBody>

                  <MDBModalFooter>
                    <MDBBtn
                      style={{ maxWidth: " 65px", maxHeight: "37px" }}
                      color="secondary"
                      onClick={toggleOpen}
                    >
                      Cerrar
                    </MDBBtn>
                    <MDBBtn
                      style={{
                        maxWidth: " 102.2px",
                        width: " 102.2px",
                        maxHeight: "37px",
                      }}
                      color="success"
                      onClick={OK}
                    >
                      Ok
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
          </>
        ) : (
          <button
            className="btn btn-outline-warning"
            onClick={() =>
              navigate("/login", {
                state: "/cart",
              })
            }
          >
            Por favor, antes de continuar, debe logearse
          </button>
        )}
      </div>
    </div>
  );
};
export default DatosAdicionales;
