import React from 'react'
import Layout from '../Layout/Layout'
const Policy = () => {
    return (
        <Layout  title={'Privacy Policy'}>
            <div className='row contactus'>
                <div className='col-md-6'>
                    <img src='images/policy.jpg' alt='Privacy Policy' style={{ width: "100%" }} />

                </div>
                <div className='col-md-4'>
                    <p>npm notice Beginning October 4, 2021, all connections ing for package installation - must use TLS 1.2 or hig plaintext http to connect. Please visit the GitHub bl
                        ng plaintext http to connect. Please visit the GitHub
                        blog for more informati
                        npm notice Beginning October 4, 2021, all connections ing for package installation - must use TLS 1.2 or hig plaintext http to connect. Please visit the GitHub bl
                        ng plaintext http to connect. Please visit the GitHub
                        blog for more informati</p>
                </div>

            </div>
        </Layout>
    )
}

export default Policy