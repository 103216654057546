import React, { useState } from "react";
import toast from "react-hot-toast";
// import Layout from "../../components/Layout.js";
import { useNavigate, useLocation, Link } from "react-router-dom";
// import "../../styles/AuthStyles.css";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import "./newLogin.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import { BASE_URL } from "../../components/Routes/global-env.js";
import Layout from "../../Layout/Layout.js";
import { BASE_URL } from "../../Routes/global-env.js";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const location = useLocation();

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "http://localhost:3000");
    myHeaders.append("Access-Control-Allow-Credentials", "true");
    myHeaders.append("Access-Control-Allow-Headers", "Authorization");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(`${BASE_URL}/api/auth/login`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(JSON.parse(result))
        if (result) {
          localStorage.setItem("user", JSON.stringify(JSON.parse(result).body));
        }
      })
      .then(
        () => {
          navigate(location.state || "/");
          window.location.reload();
        },
        (error) => {
          navigate("/");
        }
      )
      .catch((error) => toast.error("Something went wrong.", error));
  };
  return (
    <Layout title="Login - Ecommerce App">
      <form onSubmit={handleSubmit}>
        <MDBContainer
          style={{ height: "100%" }}
          fluid
          className="p-4 background-radial-gradient overflow-hidden login"
        >
          <MDBRow>
            <MDBCol
              md="6"
              className="text-center text-md-start d-flex flex-column justify-content-center"
            >
              <h1
                className="my-5 display-3 fw-bold ls-tight px-3"
                style={{ color: "hsl(218, 81%, 95%)" }}
              >
                Abgamez <br />
                <span style={{ color: "hsl(218, 81%, 75%)" }}>Store</span>
              </h1>

              <p className="px-3" style={{ color: "hsl(218, 81%, 85%)" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Eveniet, itaque accusantium odio, soluta, corrupti aliquam
                quibusdam tempora at cupiditate quis eum maiores libero
                veritatis? Dicta facilis sint aliquid ipsum atque?
              </p>
            </MDBCol>

            <MDBCol md="6" className="position-relative">
              <div
                id="radius-shape-1"
                className="position-absolute rounded-circle shadow-5-strong"
              ></div>
              <div
                id="radius-shape-2"
                className="position-absolute shadow-5-strong"
              ></div>

              <MDBCard className="my-5 bg-glass">
                <MDBCardBody className="p-5">
                  <MDBInput
                    wrapperClass="mb-4"
                    placeholder="Email"
                    id="form3"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <MDBRow className="row ">
                    <MDBCol md="10" className="col-10">
                      <MDBInput
                        wrapperClass="mb-4"
                        id="form4"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </MDBCol>
                    <MDBCol md="2" className="col-2">
                      <span
                        className="password-toggle-icon clickable-icon"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                        color="blue"
                      >
                        {showPassword ? (
                          <FaEyeSlash style={{ color: "black" }} />
                        ) : (
                          <FaEye style={{ color: "gray" }} />
                        )}
                      </span>
                    </MDBCol>
                  </MDBRow>

                  <MDBBtn
                    rounded
                    className="w-100 mb-4"
                    size="md"
                    type="submit"
                    style={{
                      outline: "none",
                      transform: "scale(1)",
                      height: "40px", // Establece una altura fija en píxeles
                    }}
                  >
                    Log in
                  </MDBBtn>
                  <MDBBtn
                    style={{
                      outline: "none",
                      transform: "scale(1)",
                      height: "40px", // Establece una altura fija en píxeles
                    }}
                    rounded
                    color="light"
                    rippleColor="dark"
                    className="w-100 mb-2 "
                    size="md"
                    onClick={() => {
                      navigate("/forgot-password");
                    }}
                  >
                    Forgot Password?
                  </MDBBtn>
                  <div className="text-center mt-3">
                    <p>
                      Not a member? <Link to={"/register"}>Register</Link>
                    </p>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </form>
    </Layout>
  );
};

export default Login;
