import React, { useEffect, useState } from "react";
// import Layout from "../../components/Layout.js";
// import AdminMenu from "../../components/AdminMenu.js";
import axios from "axios";
import toast from "react-hot-toast";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import AdminMenu from "../../components/AdminMenu";
// import { BASE_URL } from "../../components/Routes/global-env.js";
const { Option } = Select;

const UpdatePedido = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [admin, setAdmin] = useState("");

  const [id, setId] = useState("");

  //get single product
  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/pedidos/pedido/${params.id}`
      );
      setId(params.id);
      setAdmin(data.body[0].estado);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProduct();
  }, []);

  //create product function
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/pedidos/${params.id}`,
        { estado: admin }
      );
      if (data) {
        setTimeout(() => {
          toast.success("Usuario Actualizado");
        }, 500);
        navigate("/dashboard/admin/pedidos");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout title={"Dashborad - Crear Remesa"}>
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1 className="text-center">Editar Pedido</h1>
            <div className="mb-3">
              <input
                placeholder="Id del pedido"
                value={`Id del pedido # ${id}`}
                disabled
                // onChange={(e) => setName(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <Select
                bordered={false}
                placeholder="Selecciona su estado:"
                size="large"
                showSearch
                value={admin}
                className="form-select mb-3"
                onChange={(value) => {
                  setAdmin(value);
                }}
              >
                <Option value={"Solicitado"}>Solicitado</Option>
                <Option value={"Pagado"}>Pagado</Option>
                <Option value={"Entregado"}>Entregado</Option>
                <Option value={"Cancelado"}>Cancelado</Option>
              </Select>
            </div>

            <div className="mb-3 text-center">
              <button className="btn btn-primary" onClick={handleCreate}>
                Actualizar Pedido
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UpdatePedido;
