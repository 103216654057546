import React from "react";
import { Badge } from "antd";
import { NavLink } from "react-router-dom";
import { GiShoppingBag } from "react-icons/gi";
import { useCart } from "../../Carrito/use_Cart_Hooks";

const Carrito = () => {
  const { cart } = useCart();
  return (
    <>
      {cart?.length === 0 || cart === null ? (
        <NavLink
          to="/cart"
          className="nav-link"
          href="#"
          style={{
            fontSize: "17px",
            marginTop: "4px",
            marginRight: "20px",
          }}
        >
          <GiShoppingBag
            style={{
              fontSize: "25px",
              marginRight: "5px",
              transform: " translate(0px, -5px)",
            }}
          />
        </NavLink>
      ) : (
        <Badge
          count={cart.reduce((total, item) => {
            return total + item.quantity;
          }, 0)}
          showZero
          style={{
            marginTop: "10px",
            left: "1px",
            marginRight: "25px",
          }}
        >
          <NavLink
            to="/cart"
            replace
            className="nav-link"
            href="#"
            style={{
              fontSize: "17px",
              marginTop: "4px",
              marginRight: "20px",
            }}
          >
            <GiShoppingBag
              style={{
                fontSize: "25px",
                marginRight: "5px",
                transform: " translate(0px, -5px)",
              }}
            />
          </NavLink>
        </Badge>
      )}
    </>
  );
};

export default Carrito;
