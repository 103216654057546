import React, { useEffect, useState } from "react";
// import Layout from "../../components/Layout.js";
// import AdminMenu from "../../components/AdminMenu.js";
import axios from "axios";
import toast from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import AdminMenu from "../../components/AdminMenu";
// import { BASE_URL } from "../../components/Routes/global-env.js";

const Remesas = () => {
  const navigate = useNavigate();
  const [remesas, setRemesas] = useState([]);

  //get all remesas
  const getAllRemesas = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/remesas`);
      if (data.body) {
        setRemesas(data.body);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting category");
    }
  };
  const handleDelete = async (id) => {
    await axios.put(`${BASE_URL}/api/remesas/${id}`);
    setTimeout(() => {
      toast.success("Product Delete Successfully");
    }, 500);
    getAllRemesas();

    navigate("/dashboard/admin/remesas");
  };

  useEffect(() => {
    getAllRemesas();
  }, []);

  return (
    <Layout title={"Dashborad - Remesas"}>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <NavLink
              to="/dashboard/admin/create-remesa"
              className="btn btn-primary btn-lg mt-2 mb-2 text-white text-center"
              style={{ width: "90%", marginLeft: "2%", marginRight: "2%" }}
            >
              Crear Remesas
            </NavLink>

            <div className="d-flex">
              <table className="table table-striped">
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Nombre</th>
                    <th>Descripcion</th>
                    <th>Tipo</th>
                    <th>Precio</th>
                    <th>Imagen</th>

                    <th>Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {remesas?.map((remesa) => (
                    <tr key={remesa.id}>
                      <td> {remesa.nombre} </td>
                      <td style={{ maxLines: 1, width: "48px" }}>
                        {" "}
                        {remesa.descripcion}{" "}
                      </td>
                      <td> {remesa.tipo} </td>
                      <td> {remesa.precio} </td>
                      <td> {remesa.imagen} </td>

                      <td>
                        <Link
                          to={`/dashboard/admin/remesa/${remesa.id}`}
                          className="btn btn-warning mr-2"
                        >
                          Editar
                        </Link>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleDelete(remesa.id)}
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Remesas;
