import React, {  useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Routes/global-env";
import Layout from "../../Layout/Layout";
import AdminMenu from "../../components/AdminMenu";
const { Option } = Select;

const CreateProduct = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [descripction, setDescripction] = useState("");
  const [price, setPrice] = useState("");
  const [photo, setPhoto] = useState("");

  //create product function
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const productData = new FormData();
      productData.append("nombre", name);
      productData.append("descripcion", descripction);
      productData.append("precio", price);
      productData.append("tipo", slug);
      productData.append("imagen", photo);

      const { data } = await axios.post(
        `${BASE_URL}/api/remesas`,
        productData
      );
      console.log(data);
      if (data) {
        setTimeout(() => {
          toast.success("Remesa Creada Correctamente");
        }, 500);

        navigate("/dashboard/admin/remesas");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout title={"Dashborad - Create Product"}>
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>

          <div className="col-md-9">
            <h1 className="text-center">Crear Remesa</h1>
            {/* <form> */}
            <div className="mb-3">
              <input
                placeholder="Nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <input
                placeholder="Descripcion"
                value={descripction}
                onChange={(e) => setDescripction(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <input
                placeholder="Precio"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                type="number"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <Select
                bordered={false}
                placeholder="Selecciona la Tipo"
                size="large"
                showSearch
                className="form-select mb-3"
                onChange={(value) => {
                  setSlug(value);
                }}
              >
                <Option value={"USD"}>USD</Option>
                <Option value={"CUP"}>CUP</Option>
              </Select>
            </div>

            <div className="mb-3 text-center">
              <label className="btn btn-outline-secondary col-md-12">
                {photo ? photo.name : "Upload Photo"}
                <input
                  type="file"
                  name="photo"
                  accept="image/*"
                  onChange={(e) => setPhoto(e.target.files[0])}
                  hidden
                />
              </label>
            </div>
            <div className="mb-3 text-center">
              {photo && (
                <div className="text-center">
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="product_photo"
                    height={"200px"}
                    className="img img-responsive"
                  />
                </div>
              )}
            </div>

            <div className="mb-3 text-center">
              <button className="btn btn-primary" onClick={handleCreate}>
                CREATE PRODUCT
              </button>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CreateProduct;
