import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import axios from "axios";
import { BASE_URL } from "../Routes/global-env";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";

const Modal_Select_Provincia = ({ isOpen, onClose }) => {
  const [provincia, setProvincia] = useState([]);
  const getAllProvincia = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/provincias`);
      if (data.body) {
        setProvincia(data.body);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error al conectarse a la Base de Datos de Provincias");
    }
  };

  useEffect(() => {
    getAllProvincia();
  }, []);

  return (
    <>
      <MDBModal open={isOpen} tabIndex="-1" staticBackdrop>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Seleccione su Provincia</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => {
                  setTimeout(() => {
                    toast.error("Debe seleccionar la provincia deseada");
                  }, 500);
                }}
              ></MDBBtn>
            </MDBModalHeader>

            <MDBModalBody>
              <ul className="list-group list-group-flush">
                {provincia?.map((c, index) => (
                  <li
                    className="list-group-item"
                    key={index}
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    <NavLink
                      className="dropdown-item"
                      to={`/provincia/${c.nombre}`}
                      style={{ borderRadius: "20px" }}
                      onClick={() => {
                        localStorage.setItem("provincia", c.nombre);
                        onClose();
                      }}
                    >
                      {c.nombre}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                style={{ maxWidth: " 65px", maxHeight: "37px" }}
                color="secondary"
                onClick={() => {
                  setTimeout(() => {
                    toast.error("Debe seleccionar la provincia deseada");
                  }, 500);
                }}
              >
                Cerrar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default Modal_Select_Provincia;
